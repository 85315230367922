
;(function ( $ ) {
  'use strict';

  $.fn.pros_variation_swatches_form = function () {
    return this.each( function() {
      var $form = $( this ),
        clicked = null,
        selected = [];

      const mark_as_unavailable = () => {

        $form.find( '.pro-swatches-item.pro-swatches-not-available' ).removeClass( 'pro-swatches-not-available' );

        $('.pro-swatches-wrapper').each( function() {
          const that = $(this), 
                select = that.find('select');

          let founded = [];
          select.find('option').each( function(){
            const option = $(this).val();
            
            if( option != "" ){
              founded.push( option );
            }
          } )

          that.find('.pro-swatches-item').each( function(){
            var item = $(this),
                data = item.data('value');

            if( !founded.includes(data) ){
              item.addClass("pro-swatches-not-available")
            }
          })
        })
      }

      $form.on( 'click', '.pro-swatches-item', function ( e ) {
          e.preventDefault();

          var $el = $( this ),
            $select = $el.closest( '.value' ).find( 'select' ),
            attribute_name = $select.data( 'attribute_name' ) || $select.attr( 'name' ),
            value = $el.data( 'value' );
           
          $select.trigger( 'focusin' );
          
          if( $el.hasClass("pro-swatches-not-available") ){
            return;
          }

          // Check if this variation combination is available
          if ( ! $select.find( 'option[value="' + value + '"]' ).length ) {
            
            $el.siblings( '.pro-swatches-item' ).removeClass( 'selected' );
            $select.val( '' ).change();
            return;
          }

          clicked = attribute_name;

          if ( selected.indexOf( attribute_name ) === -1 ) {
            selected.push(attribute_name);
          }

          if ( $el.hasClass( 'selected' ) ) {
            $select.val( '' );
            $el.removeClass( 'selected' );

            delete selected[selected.indexOf(attribute_name)];
          } else {
            $el.addClass( 'selected' ).siblings( '.selected' ).removeClass( 'selected' );
            $select.val( value );
          }
          
          $select.change();

          mark_as_unavailable();
        } )
        .on( 'click', '.reset_variations', function () {
          const form = $( this ).closest( '.variations_form' );
          form.find( '.pro-swatches-item.selected' ).removeClass( 'selected' );
          form.find( '.pro-swatches-item.pro-swatches-not-available' ).removeClass( 'pro-swatches-not-available' );
          selected = [];
        } );
    } );
  };

  $.fn.pros_tooltip = function () {
    return this.each( function() {
      var $item = $( this ),
        body = $('body'),
        title = $item.attr('title'),
        tooltip_item = $("<div>", {
          "class": "pro-swatches-tooltip"
        });

      function getOffset( el ) {
        var _x = 0;
        var _y = 0;
        while( el && !isNaN( el.offsetLeft ) && !isNaN( el.offsetTop ) ) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
      }

      if( title ){
        $item.mouseenter(function() {
          tooltip_item.text( title );

          const offset = getOffset( $item[0] );
          body.append( tooltip_item );

          tooltip_item.css({
            top: `${offset.top - 10}px`,
            left: `${offset.left + 7}px`
          })

        }).mouseleave(function() {
          body.find('.pro-swatches-tooltip').remove();
        });
      }
    } );
  };

  $( function () {
    $( '.variations_form' ).pros_variation_swatches_form();
    $( '.pro-swatches-item' ).pros_tooltip();
  } );
  
})( jQuery );